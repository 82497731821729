<template>
  <section>
    <b-tabs>
      <b-tab title="Detalle" active>
        <b-overlay :show="showOverlay" rounded="sm">
          <b-row v-for="(blending, index) in blendings" :key="index">
            <b-col>
              <b-card no-body>
                <div class="card-header">
                  <!-- Title & SubTitle -->
                  <div>
                    <b-card-title>{{ blending.topRowData.Lote }}</b-card-title>
                    <b-card-sub-title></b-card-sub-title>
                  </div>

                  <!-- Card Actions -->
                  <div class="heading-elements">
                    <ul class="list-inline mb-0">
                      <li>
                        <b-button
                          variant="flat-secondary"
                          class="btn-icon"
                          @click="() => downloadExcel(index)"
                        >
                          <feather-icon icon="DownloadIcon" size="20" />
                        </b-button>
                      </li>
                    </ul>
                  </div>
                </div>

                <!-- Card Body  -->
                <b-card-body style="width: 100%">
                  <ag-grid-vue
                    style="width: 100%; height: 500px"
                    class="ag-theme-alpine"
                    :columnDefs="blending.columnDefs"
                    :rowData="blending.rowData"
                    @grid-ready="
                      (params) => {
                        gridTables[index] = params;
                        onGridReady(params, blending.topRowData);
                      }
                    "
                  ></ag-grid-vue>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-overlay>
      </b-tab>

      <!--
      <b-tab title="Detalle Diario" active>
        <b-col>
          <b-card-title>
            <home-card-blending
              :dataUrl="detallesCabulco"
              style="margin-left: -15px"
            >
            </home-card-blending>
          </b-card-title>
        </b-col>
        <b-col>
          <b-card-title>
            <home-card-blending
              :dataUrl="detallesCoronel"
              style="margin-left: -15px"
            >
            </home-card-blending>
          </b-card-title>
        </b-col>
      </b-tab>
      -->
      <b-tab title="Listado Blendings">
        <detalle-semanal-tab-vue :detalle_semanal="detalle_semanal"></detalle-semanal-tab-vue>
      </b-tab>
      <b-tab title="Contratos">
        <match-contratos-tab-vue :match_contratos="match_contratos"></match-contratos-tab-vue>
      </b-tab>
      <b-tab title="Dashboard">
        <dashboard-tab-vue :dashboard_data="dashboard_data"></dashboard-tab-vue>
      </b-tab>
    </b-tabs>

    <!--
    <b-button
      :to="{ name: 'dashboard-fiordo-historico-planificacion', params: {} }"
      style="
        float: right;
        margin-right: 15px;
        background-color: #053743 !important;
        height: 50px;
      "
      >Ir al Histórico de Planificaciones</b-button
    >
    -->
  </section>
</template>

<script>
import
{
  BCardTitle,
  BFormDatepicker,
  BCalendar,
  BRow,
  BCol,
  BCardBody,
  BCardText,
  BCard,
  BLink,
  BButton,
  BTabs,
  BTab,
  BOverlay



} from "bootstrap-vue";

import useApiServices from '@/services/useApiServices.js';
import BrunaElementLoader from "@/views/brunacomponents/BrunaElementLoader.vue";
import HomeCardBlending from './HomeCardBlending.vue';
import DetalleSemanalTabVue from "./DetalleSemanalTab.vue";
import MatchContratosTabVue from "./MatchContratosTab.vue";

import DashboardTabVue from "./DashboardTab.vue"

import { AgGridVue } from "ag-grid-vue";

import axios from "@axios";

export default {
  components: {

    BFormDatepicker,
    BRow,
    BCol,
    BCardTitle,
    BrunaElementLoader,
    HomeCardBlending,
    BCardTitle,
    BCardBody,
    BCardText,
    BCard,
    BLink,
    BButton,
    BTabs,
    BTab,
    BCalendar,
    DetalleSemanalTabVue,
    MatchContratosTabVue,
    AgGridVue,
    BOverlay,
    DashboardTabVue
  },

  created()
  {

    this.planificationId = this.$route.params.planification_id;


    axios.get(useApiServices.planificacionRead + "/" + this.planificationId)
      .then((response) =>
      {

        this.showOverlay = false
        this.blendings = response.data.detalle
        this.detalle_semanal = response.data.detalle_semanal
        this.match_contratos = response.data.match_contratos
        this.dashboard_data = response.data.dashboard_data

      })
      .catch((error) =>
      {

      });


  },

  methods: {

    downloadExcel(index)
    {

      this.gridTables[index].api.exportDataAsExcel();

    },

    onGridReady(params, topRowData)
    {
      params.api.setPinnedTopRowData([topRowData]);

      //console.log("onGridReady", params, blending)
    }
  },


  data()
  {
    return {
      planificationId: null,
      detallesCabulco: useApiServices.detallesCabulco,
      detallesCoronel: useApiServices.detallesCoronel,

      blendings: [],
      detalle_semanal: [],
      match_contratos: [],
      dashboard_data: [],

      gridTables: {},
      showOverlay: true
    }
  }
};
</script>

<style lang="scss">
.ag-row-pinned {
  background-color: #ffe4c4 !important;
}
</style>

