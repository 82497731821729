<template>
  <div>
    <b-button-group size="sm">
      <b-button variant="outline-primary" @click="showDownloadModal">
        <feather-icon icon="AlignJustifyIcon" class="mr-50" />
        <span class="align-middle">Detalles</span>
      </b-button>
    </b-button-group>

    <b-modal
      :ref="'detalle-blending-modal-' + cellValue.data.id"
      ok-only
      ok-title="Accept"
      centered
      size="xl"
      :title=" 'Detalle blending: ' + cellValue.data.name "
    >
      <b-card no-body>
        <div class="card-header">
          <!-- Title & SubTitle -->
          <div>
            <b-card-title>Lotes</b-card-title>
            <b-card-sub-title></b-card-sub-title>
          </div>

          <!-- Card Actions -->
          <div class="heading-elements">
            <ul class="list-inline mb-0">
              <li>
                <b-button
                  variant="flat-secondary"
                  class="btn-icon"
                  @click="() => downloadExcel()"
                >
                  <feather-icon icon="DownloadIcon" size="20" />
                </b-button>
              </li>
            </ul>
          </div>
        </div>

        <!-- Card Body  -->
        <b-card-body style="width: 100%">
          <ag-grid-vue
            style="width: 100%; height: 500px"
            class="ag-theme-alpine"
            :columnDefs="columnDefs"
            :rowData="cellValue.data.meta_data.lotes"
            @grid-ready="
              (params) => {
                gridTable = params;
              }
            "
          ></ag-grid-vue>
        </b-card-body>
      </b-card>
    </b-modal>
  </div>
</template>
  
  <script>
import
{
  BButtonGroup,
  BButton,
  BModal,
  BCardText,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,

  BCard,
  BCardTitle,
  BCardSubTitle
} from "bootstrap-vue";

import AgGridTable from "@/views/brunacomponents/ag-grid-table/AgGridTable.vue";
import BrunaElementLoader from "@/views/brunacomponents/BrunaElementLoader.vue";

import useApiServices from "@/services/useApiServices.js";


import { AgGridVue } from "ag-grid-vue";

export default {
  components: {
    BRow,
    BCol,
    BButtonGroup,
    BButton,
    BModal,
    BCardText,
    BDropdown,
    BDropdownItem,
    AgGridTable,
    BrunaElementLoader,
    AgGridVue,

    BCard,
  BCardTitle,
  BCardSubTitle

  },

  directives: {},

  data: function ()
  {
    return {
      gridTable: null,
      cellValue: null,
      columnDefs: [
        {
          "field": "Lote",
          "headerName": "Lote",
          "pinned": "left",
          "filter": "agSetColumnFilter",
          "floatingFilter": true,
          "sortable": true
        },
        {
          "field": "FFA",
          "headerName": "FFA",
          "filter": "agSetColumnFilter",
          "floatingFilter": true,
          "sortable": true
        },
        {
          "field": "Hum",
          "headerName": "Hum",
          "filter": "agSetColumnFilter",
          "floatingFilter": true,
          "sortable": true
        },
        {
          "field": "Sal",
          "headerName": "Sal",
          "filter": "agSetColumnFilter",
          "floatingFilter": true,
          "sortable": true
        },
        {
          "field": "TVN",
          "headerName": "TVN",
          "filter": "agSetColumnFilter",
          "floatingFilter": true,
          "sortable": true
        },
        {
          "field": "Hist",
          "headerName": "Hist",
          "filter": "agSetColumnFilter",
          "floatingFilter": true,
          "sortable": true
        },
        {
          "field": "Prot",
          "headerName": "Prot",
          "filter": "agSetColumnFilter",
          "floatingFilter": true,
          "sortable": true
        },
        {
          "field": "Arena",
          "headerName": "Arena",
          "filter": "agSetColumnFilter",
          "floatingFilter": true,
          "sortable": true
        },
        {
          "field": "Grasa",
          "headerName": "Grasa",
          "filter": "agSetColumnFilter",
          "floatingFilter": true,
          "sortable": true
        },
        {
          "field": "Ceniza",
          "headerName": "Ceniza",
          "filter": "agSetColumnFilter",
          "floatingFilter": true,
          "sortable": true
        },
        {
          "field": "AUX",
          "headerName": "AUX",
          "filter": "agSetColumnFilter",
          "floatingFilter": true,
          "sortable": true
        },
        {
          "field": "PAC",
          "headerName": "PAC",
          "filter": "agSetColumnFilter",
          "floatingFilter": true,
          "sortable": true
        },
        {
          "field": "Prx",
          "headerName": "Prx",
          "filter": "agSetColumnFilter",
          "floatingFilter": true,
          "sortable": true
        },
        {
          "field": "SKU",
          "headerName": "SKU",
          "filter": "agSetColumnFilter",
          "floatingFilter": true,
          "sortable": true
        },
        {
          "field": "AUX2",
          "headerName": "AUX2",
          "filter": "agSetColumnFilter",
          "floatingFilter": true,
          "sortable": true
        },
        {
          "field": "AÑEJ",
          "headerName": "AÑEJ",
          "filter": "agSetColumnFilter",
          "floatingFilter": true,
          "sortable": true
        },
        {
          "field": "AUX ER",
          "headerName": "AUX ER",
          "filter": "agSetColumnFilter",
          "floatingFilter": true,
          "sortable": true
        },
        {
          "field": "FFA__1",
          "headerName": "FFA__1",
          "filter": "agSetColumnFilter",
          "floatingFilter": true,
          "sortable": true
        },
        {
          "field": "Origen",
          "headerName": "Origen",
          "filter": "agSetColumnFilter",
          "floatingFilter": true,
          "sortable": true
        },
        {
          "field": "Status",
          "headerName": "Status",
          "filter": "agSetColumnFilter",
          "floatingFilter": true,
          "sortable": true
        },
        {
          "field": "TVN__1",
          "headerName": "TVN__1",
          "filter": "agSetColumnFilter",
          "floatingFilter": true,
          "sortable": true
        },
        {
          "field": "AUX2__1",
          "headerName": "AUX2__1",
          "filter": "agSetColumnFilter",
          "floatingFilter": true,
          "sortable": true
        },
        {
          "field": "Especie",
          "headerName": "Especie",
          "filter": "agSetColumnFilter",
          "floatingFilter": true,
          "sortable": true
        },
        {
          "field": "Prot__1",
          "headerName": "Prot__1",
          "filter": "agSetColumnFilter",
          "floatingFilter": true,
          "sortable": true
        },
        {
          "field": "Stock ton",
          "headerName": "Stock ton",
          "filter": "agSetColumnFilter",
          "floatingFilter": true,
          "sortable": true
        },
        {
          "field": "Fecha Elab",
          "headerName": "Fecha Elab",
          "filter": "agSetColumnFilter",
          "floatingFilter": true,
          "sortable": true
        },
        {
          "field": "Tpo Antiox",
          "headerName": "Tpo Antiox",
          "filter": "agSetColumnFilter",
          "floatingFilter": true,
          "sortable": true
        },
        {
          "field": "Tpo.Envase",
          "headerName": "Tpo.Envase",
          "filter": "agSetColumnFilter",
          "floatingFilter": true,
          "sortable": true
        },
        {
          "field": "Ubicación",
          "headerName": "Ubicación",
          "filter": "agSetColumnFilter",
          "floatingFilter": true,
          "sortable": true
        },
        {
          "field": "AUX Especie",
          "headerName": "AUX Especie",
          "filter": "agSetColumnFilter",
          "floatingFilter": true,
          "sortable": true
        },
        {
          "field": "Observaciones",
          "headerName": "Observaciones",
          "filter": "agSetColumnFilter",
          "floatingFilter": true,
          "sortable": true
        },
        {
          "field": "Status Agrupado",
          "headerName": "Status Agrupado",
          "filter": "agSetColumnFilter",
          "floatingFilter": true,
          "sortable": true
        },
        {
          "field": "Asignación Venta",
          "headerName": "Asignación Venta",
          "filter": "agSetColumnFilter",
          "floatingFilter": true,
          "sortable": true
        },
        {
          "field": "Plan Recuperacion",
          "headerName": "Plan Recuperacion",
          "filter": "agSetColumnFilter",
          "floatingFilter": true,
          "sortable": true
        },
        {
          "field": "comentario Status",
          "headerName": "comentario Status",
          "filter": "agSetColumnFilter",
          "floatingFilter": true,
          "sortable": true
        }
      ]

    };
  },
  beforeMount()
  {
    // this.params contains the cell & row information and is made available to this component at creation time
    // see ICellRendererParams below for more details
    this.cellValue = this.getValueToDisplay(this.params);
  },
  methods: {

    downloadExcel()
    {

      this.gridTable.api.exportDataAsExcel();

    },

    // gets called whenever the user gets the cell to refresh
    refresh(params)
    {
      // set value into cell again
      this.cellValue = this.getValueToDisplay(params);
    },

    showDownloadModal()
    {
      this.$refs['detalle-blending-modal-' + this.cellValue.data.id].show()
    },




    getValueToDisplay(params)
    {
      return params;
      //return params.valueFormatted ? params.valueFormatted : params.value;
    },
  },
};
  </script>
  
  <style lang="scss" scoped>
@import "@core/scss/vue/pages/ui-feather.scss";
</style>
  