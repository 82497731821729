<template>
    <div>

      <b-row>
            <b-col cols="3" v-for="(item, index) in dashboard_data" :key="index">
                <b-card class="text-center">
                  
                  <div class="heading-elements">
                    <ul class="list-inline col text-right">
                      <li>
                        <feather-icon
                          icon="HelpCircleIcon"
                          
                          size="20"
                        />
                      </li>
                    </ul>
                  </div>

                  <b-avatar class="mb-1" :variant="item.color" size="45">
                    <feather-icon size="21" :icon="item.icon" />
                  </b-avatar>
                  <div class="truncate">
                    <h5 class="mb-25 font-weight-bolder">{{ item.title }}</h5>
                    <span>{{ item.subtitle }}</span>
                  </div>
                </b-card>
            </b-col>
        </b-row>


      
    </div>
  </template>
  
  <script>
  import {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BFormFile,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BFormSelect,
    BIconNodeMinusFill,
    BTabs,
    BSpinner,
    BAlert,
    BTab,
    BLink,
    VBPopover,
    VBTooltip,
    BFormGroup,
    BFormInvalidFeedback,
  } from "bootstrap-vue";
  import RepeatStatistics from "@/views/clients/fiordo/components/RepeatStatistics";
  import axios from "@axios";
  
  import useApiServices from "@/services/useApiServices.js";
  import BlendingActions from "./BlendingActions.vue"
  import DetalleSemanalTabActions from './DetalleSemanalTabActions.vue'
  
  
  import { AgGridVue } from "ag-grid-vue";
  
  export default {
    data() {



      return {

        itemsData1: [
          {
            color: "info",
            icon: "TrendingUpIcon",
            title: "USD $3.089.450",
            subtitle: "Valor Pre Blending"
          },
          {
            color: "info",
            icon: "TrendingUpIcon",
            title: "USD $3.089.450",
            subtitle: "Valor Pre Blending"
          },
          {
            color: "info",
            icon: "TrendingUpIcon",
            title: "USD $3.089.450",
            subtitle: "Valor Pre Blending"
          },
          {
            color: "info",
            icon: "TrendingUpIcon",
            title: "USD $3.089.450",
            subtitle: "Valor Pre Blending"
          },
          {
            color: "info",
            icon: "TrendingUpIcon",
            title: "USD $3.089.450",
            subtitle: "Valor Pre Blending"
          },
          {
            color: "info",
            icon: "TrendingUpIcon",
            title: "USD $3.089.450",
            subtitle: "Valor Pre Blending"
          },
          {
            color: "info",
            icon: "TrendingUpIcon",
            title: "USD $3.089.450",
            subtitle: "Valor Pre Blending"
          },
          {
            color: "info",
            icon: "TrendingUpIcon",
            title: "USD $3.089.450",
            subtitle: "Valor Pre Blending"
          },
          {
            color: "info",
            icon: "TrendingUpIcon",
            title: "USD $3.089.450",
            subtitle: "Valor Pre Blending"
          },
          {
            color: "info",
            icon: "TrendingUpIcon",
            title: "USD $3.089.450",
            subtitle: "Valor Pre Blending"
          },
        ]

      };
    },
    components: {
      BRow,
      BCol,
      BCard,
      BButton,
      BCardHeader,
      BFormFile,
      BCardTitle,
      BCardSubTitle,
      BCardText,
      BCardBody,
      BFormSelect,
      BTabs,
      VBPopover,
      VBTooltip,
      BTab,
      BLink,
      BAlert,
      BSpinner,
      RepeatStatistics,
      BlendingActions: BlendingActions,
      AgGridVue,
    },
  
    props: {

      dashboard_data: {
            type: Array,
            required: true,
        },
     
      },  
  
    directives: {
      "b-tooltip": VBTooltip,
      "b-popover": VBPopover,
    },
  
    mounted() {
     
    },
  
    methods: {
  
   
  
    },
  };
  </script>
  
  <style scoped>
  span {
    font-size: 14px;
  }
  </style>