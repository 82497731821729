<template>
  <div>
    <b-row>
      <b-col>
        <repeat-statistics :data="itemsData" size="3" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card no-body>
                <div class="card-header">
                  <!-- Title & SubTitle -->
                  <div>
                    <b-card-title>Blendings</b-card-title>
                    <b-card-sub-title></b-card-sub-title>
                  </div>

                  <!-- Card Actions -->
                  <div class="heading-elements">
                    <ul class="list-inline mb-0">
                      <li>
                        <b-button
                          variant="flat-secondary"
                          class="btn-icon"
                          @click="() => downloadExcel()"
                        >
                          <feather-icon icon="DownloadIcon" size="20" />
                        </b-button>
                      </li>
                    </ul>
                  </div>
                </div>

                <!-- Card Body  -->
                <b-card-body style="width: 100%">
                  <ag-grid-vue
                    style="width: 100%; height: 500px"
                    class="ag-theme-alpine"
                    :columnDefs="detalle_semanal.columnDefs"
                    :rowData="detalle_semanal.rowData"
                    @grid-ready="
                      (params) => {
                        gridTable = params;
                      }
                    "
                  ></ag-grid-vue>
                </b-card-body>
              </b-card>
        

      </b-col>
    </b-row>
    
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BCardHeader,
  BFormFile,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BFormSelect,
  BIconNodeMinusFill,
  BTabs,
  BSpinner,
  BAlert,
  BTab,
  BLink,
  VBPopover,
  VBTooltip,
  BFormGroup,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import RepeatStatistics from "@/views/clients/fiordo/components/RepeatStatistics";
import axios from "@axios";

import useApiServices from "@/services/useApiServices.js";
import BlendingActions from "./BlendingActions.vue"
import DetalleSemanalTabActions from './DetalleSemanalTabActions.vue'


import { AgGridVue } from "ag-grid-vue";

export default {
  data() {
    return {
      gridTable: null
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BFormFile,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BFormSelect,
    BTabs,
    VBPopover,
    VBTooltip,
    BTab,
    BLink,
    BAlert,
    BSpinner,
    RepeatStatistics,
    BlendingActions: BlendingActions,
    AgGridVue,

    "detalleSemanalTabActions": DetalleSemanalTabActions
  },

  props: {
    detalle_semanal: {
            type: Array,
            required: true,
        },
    },  

  directives: {
    "b-tooltip": VBTooltip,
    "b-popover": VBPopover,
  },

  mounted() {
    axios
      .get(useApiServices.detallesSemanalStatics)
      .then((response) => {
        this.itemsData = response.data;
      })
      .catch((error) => {});
  },

  methods: {

    downloadExcel()
    {

      this.gridTable.api.exportDataAsExcel();

    },


  },
};
</script>

<style scoped>
span {
  font-size: 14px;
}
</style>