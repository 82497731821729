<template>
  <div>
    <b-button variant="primary" size="sm" @click="goToDetail()">
        <span class="align-middle">Ver detalle</span>
      </b-button>
  </div>
</template>

<script>
import { BButtonGroup, BButton, BModal, BCardText, BRow, BCol, BDropdown, BDropdownItem } from "bootstrap-vue";
import router from '@/router'

import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'
export default {
  components: {
    BRow,
    BCol,
    BButtonGroup,
    BButton,
    BModal,
    BCardText,
    BDropdown,
    BDropdownItem,
    BrunaElementLoader
  },
  methods: {
    goToDetail() {
      router.push({ path: `/dashboard/fiordo/blending/detalle-planificacion-blending` })
    }
  }

}
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/pages/ui-feather.scss";
</style>